
import { defineComponent } from "vue";

export default defineComponent({
 name: "ScrollTop",
 data(){
      return {
      isScrollDown: false as boolean
   }
 },
  methods:{
    onScroll(){
     const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
     currentScrollPosition == 0 ? this.isScrollDown = false:  this.isScrollDown = true;
    },
    gotop(){
      window.scrollTo(0,0);
    }
  },
  mounted () {
      window.addEventListener('scroll', this.onScroll)
    },
   beforeUnmount () {
     window.removeEventListener('scroll', this.onScroll)
  }

})
