
import { defineComponent} from 'vue'
import Card from '@/components/Card.vue'
import FilterInput from '@/components/FilterInput.vue'
import SearchInput from '@/components/SearchInput.vue';
import useSearchQuery from '@/composables/useSearchQuery'
import useFilterQuery from '@/composables/useFilterQuery'
import Loading from '@/components/Loading.vue'
import Error from '@/components/Error.vue'
import ICountry from '@/types/country'


export default defineComponent({
  setup() {
   const { searchResult, getSearchQuery} = useSearchQuery();
   const { filterdResult, getFiltedData } = useFilterQuery();

  return{ 
          searchResult, 
          getSearchQuery,
          filterdResult, 
          getFiltedData
        }
  },

  name: 'Home',
  components: { 
    Card,
    Loading,
    Error,
    SearchInput,
    FilterInput
  },

  data(){
    return{
        dataResult : this.$store.state.allCountry as Array<ICountry>,
    }
  },
  created: function(){

    if(this.allCountries.length == 0 ){ 
      this.$store.dispatch('getAllCountry', {"urlParam" : "all"});
     }
  },
  computed:{
     allCountries: function(){
      return this.$store.state.allCountry;
    }
  },
   methods:{
     onFilter(txt: string){
       this.filterdResult = this.getFiltedData(txt, this.allCountries);
       this.dataResult =   this.filterdResult;
     },
     onSearchItem(txt: string){
        this.getSearchResult(txt);
     },
     onSearchSubmit(txt : string){
       if(txt && txt.length > 0) this.getSearchResult(txt);
     },
     getSearchResult(_qry: string){
         this.searchResult =  this.getSearchQuery(_qry, this.allCountries);
         this.dataResult = this.searchResult;
     }
   }
});
