
import { defineComponent } from "vue";

export default defineComponent({
 name: "FilterInput",
 data(){
     return{
       val: "" as string
     }
 },
 watch:{
     val: function(_val: string){
      this.$emit('value', _val);
     }
 }

})
