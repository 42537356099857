
import { defineComponent} from 'vue';
import ThemeSwitch from '@/components/ThemeSwitch.vue'
import ScrollTop from '@/components/ScrollTop.vue'

export default defineComponent({
  name: 'App',
  components: { 
    ThemeSwitch,
    ScrollTop
  }
});
