import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "country-input" }
const _hoisted_3 = { class: "result-count" }
const _hoisted_4 = { class: "card-container" }
const _hoisted_5 = {
  key: 0,
  class: "noresult"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_FilterInput = _resolveComponent("FilterInput")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchInput, {
        onOnSearchText: _ctx.onSearchItem,
        onOnGetCountry: _ctx.onSearchSubmit
      }, null, 8, ["onOnSearchText", "onOnGetCountry"]),
      _createVNode(_component_FilterInput, { onValue: _ctx.onFilter }, null, 8, ["onValue"])
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dataResult.length) + " / " + _toDisplayString(_ctx.allCountries.length), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Loading),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataResult, (country, index) => {
        return (_openBlock(), _createBlock(_component_Card, {
          key: {index},
          country: country
        }, null, 8, ["country"]))
      }), 128)),
      _createVNode(_component_Error),
      (_ctx.dataResult.length == 0 && !this.$store.state.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, " No Result Found ! "))
        : _createCommentVNode("", true)
    ])
  ]))
}