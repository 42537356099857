
import { defineComponent, PropType } from 'vue';
import ICountry from '@/types/country';


export default defineComponent({
  name: 'Card',
  props: {
    country: {
      required: true,
      type: Object as PropType<ICountry>
    },
  }
});
