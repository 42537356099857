import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mode-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleTheme && _ctx.toggleTheme(...args))),
    class: "theme-switch"
  }, [
    _createElementVNode("span", null, [
      _createElementVNode("i", {
        class: _normalizeClass([_ctx.isDarkTheme ? _ctx.sunIcon  : _ctx.moonIcon ]),
        "aria-hidden": "true"
      }, null, 2)
    ]),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.mode) + " Mode", 1)
  ]))
}