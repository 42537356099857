
import { defineComponent} from 'vue';


export default defineComponent({
  name: 'SearchInput',
  data(){
    return{
        searchText : "" as string
    }
  },
  computed:{

  },
  watch:{
     searchText :function (_qry: string){
       this.$emit('onSearchText', _qry);
     }
   },
   methods:{
     getCountry(_qry: string){
       this.$emit('onGetCountry', _qry);
     }
   }
});
